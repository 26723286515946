import type { Locale } from "@kavval/lingui-config/locales";

import { formatDistance, formatElevation, formatTime } from "..";

export const formatGenericDistance = (
  locale: Locale,
  distance: number,
  /**
   * @description The unit of the distance. If null, the distance will be formatted as meters or kilometers.
   */
  distanceUnit: "minutes" | "meters" | "elevation" | null | undefined,
  showUnit = true
) => {
  if (distanceUnit === "minutes") {
    return formatTime({ time: distance });
  }

  if (distanceUnit === "elevation") {
    return formatElevation({ locale, elevation: distance, shouldDisplayUnit: showUnit });
  }

  return formatDistance({ locale, distance, shouldDisplayUnit: showUnit });
};
