import type { ReactNode } from "react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import type { Maybe } from "@kavval/ts-utils";

import { formatDate } from "@booking/lib/formatters";

const useTextConditions = ({
  availableAt,
  expiresAt,
  maxRegistrations,
  minBirthdate,
  maxBirthdate,
}: {
  availableAt: Maybe<string>;
  expiresAt: Maybe<string>;
  maxRegistrations: Maybe<number>;
  minBirthdate: Maybe<string>;
  maxBirthdate: Maybe<string>;
}) => {
  const { i18n } = useLingui();

  const conditions: Array<ReactNode> = [];

  if (availableAt && expiresAt) {
    const from = formatDate(i18n.locale, availableAt, {
      weekday: "long",
      day: "numeric",
      month: "long",
      // hour: "numeric",
      // minute: "numeric",
    });
    const to = formatDate(i18n.locale, expiresAt, {
      weekday: "long",
      day: "numeric",
      month: "long",
      // hour: "numeric",
      // minute: "numeric",
    });
    conditions.push(
      <p key="dateCondition">
        <Trans id="page.tickets.availableFromUntil">
          Tarif disponible du {from} au {to} (inclus).
        </Trans>{" "}
      </p>
    );
  } else if (availableAt) {
    const from = formatDate(i18n.locale, availableAt, {
      weekday: "long",
      day: "numeric",
      month: "long",
      // hour: "numeric",
      // minute: "numeric",
    });
    conditions.push(
      <p key="dateCondition">
        <Trans id="page.tickets.availableFrom">Tarif disponible à partir du {from}.</Trans>{" "}
      </p>
    );
  } else if (expiresAt) {
    const to = formatDate(i18n.locale, expiresAt, {
      weekday: "long",
      day: "numeric",
      month: "long",
      // hour: "numeric",
      // minute: "numeric",
    });
    conditions.push(
      <p key="dateCondition">
        <Trans id="page.tickets.availableUntil">Tarif disponible jusqu'au {to}.</Trans>{" "}
      </p>
    );
  }

  if (maxRegistrations) {
    conditions.push(
      <p key="maxRegistrations">
        <Trans id="page.tickets.maxRegistrations">
          Dans la limite de {maxRegistrations} participants.
        </Trans>{" "}
      </p>
    );
  }

  if (minBirthdate || maxBirthdate) {
    const maxBirthdateText = maxBirthdate
      ? formatDate(i18n.locale, maxBirthdate, {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })
      : null;
    const minBirthdateText = minBirthdate
      ? formatDate(i18n.locale, minBirthdate, {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })
      : null;

    if (!maxBirthdate) {
      conditions.push(
        <p key="minBirthdate">
          <Trans id="page.tickets.minBirthdateCondition">
            Réservé aux personnes nées à partir du {minBirthdateText}.
          </Trans>{" "}
        </p>
      );
    } else if (!minBirthdate) {
      conditions.push(
        <p key="maxBirthdate">
          <Trans id="page.tickets.maxBirthdateCondition">
            Réservé aux personnes nées avant le {maxBirthdateText}.
          </Trans>{" "}
        </p>
      );
    } else {
      conditions.push(
        <p key="maxBirthdate">
          <Trans id="page.tickets.minAndMaxBirthdateCondition">
            Réservé aux personnes nées entre le {minBirthdateText} et le {maxBirthdateText}.
          </Trans>{" "}
        </p>
      );
    }
  }

  return conditions;
};

export default useTextConditions;
