import type { I18n } from "@lingui/core";

import { getDisciplineTranslationId } from "@kavval/constants";

export const translateDiscipline = (
  i18n: I18n,
  discipline: Parameters<typeof getDisciplineTranslationId>[0]
) => {
  const noExtract = i18n._.bind(i18n);
  return noExtract(getDisciplineTranslationId(discipline));
};
