import type { Locale } from "@kavval/lingui-config/locales";

type FormatElevation = (params: {
  locale: Locale;
  elevation: number;
  shouldDisplayUnit?: boolean;
}) => string;
export const formatElevation: FormatElevation = ({
  locale,
  elevation,
  shouldDisplayUnit = true,
}) => {
  return `${new Intl.NumberFormat(locale).format(elevation)}${shouldDisplayUnit ? " m" : ""}`;
};
