import type { FC, ReactNode } from "react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import clsx from "clsx";
import { isEmpty, map } from "lodash";

import type {
  Activity,
  CurrencyEnum,
  DistanceUnitEnum,
  GenericDiscipline,
} from "@kavval/constants";
import {
  ActivityEnum,
  EventStatusEnum,
  getDisciplineTranslationId,
  isMultiSports as getIsMultiSports,
} from "@kavval/constants";
import type { Locale } from "@kavval/lingui-config/locales";
import type { Maybe } from "@kavval/ts-utils";
import { ActivityIcon, Chip } from "@kavval/ui";
import CalendarIcon from "@kavval/ui/OutlinedIcons/CalendarIcon";
import RunningBasicIcon from "@kavval/ui/OutlinedIcons/RunningBasicIcon";
import MountainPicto from "@kavval/ui/Picto/MountainPicto";

import ShoppingCartContainer from "@booking/components/ShoppingCartContainer";
import {
  formatDate,
  formatDateRange,
  formatDistance,
  formatNumber,
  formatPrice,
} from "@booking/lib/formatters";
import useOfferAvailabilityMessages, {
  offerIsLimited,
} from "@booking/lib/hooks/useOfferAvailabilityMessages";

// import { assuranceChoices } from "@booking/pages/Booking/Details/AssuranceSelector";

// import styles from "./styles.module.css";

const PriceItem = ({
  label,
  price,
  currency,
}: {
  label: ReactNode;
  price: number;
  currency: CurrencyEnum;
}) => (
  <div className="text-storm-grey text-sm font-bold flex flex-row justify-between items-start ">
    <div className="shrink">{label}</div>
    <div className="">
      <div className="flex flex-row items-center pl-4">
        {price === 0 && <Trans id="generic.free">Gratuit</Trans>}
        {price > 0 && formatPrice(price, currency)}
      </div>
    </div>
  </div>
);

type ShoppingCartItemProps = {
  item: {
    offer: {
      // title: Maybe<string>;
      price?: number;
      fees?: number;
      currency?: CurrencyEnum;
      raceEdition: RaceSummaryProps["raceEdition"];
      expiresAt?: Maybe<string>;
      remainingRegistrations?: Maybe<number>;
    };
    options: Array<{ title: string; price: number; currency: CurrencyEnum }>;
  };
};

const ShoppingCartItem = ({ item: { offer, options } }: ShoppingCartItemProps) => {
  const { price, fees, currency, raceEdition } = offer;
  const offerAvailabilityMessages = useOfferAvailabilityMessages({
    expiresAt: offer.expiresAt,
    remainingRegistrations: offer.remainingRegistrations,
    bookable: true,
  });

  return (
    <>
      <div className="text-sm text-fjord-grey font-bold md:border-l-4 border-electric-blue md:pl-3 md:-ml-4">
        {raceEdition.race.formattedTitle}
      </div>
      {/* <div className="leading-none tracking-tight font-bold text-lg mb-1">{title}</div> */}
      <RaceSummary raceEdition={raceEdition} />
      <div>
        {price && currency ? (
          <PriceItem
            label={<Trans id="component.ShoppingCart.priceLabel">Inscription</Trans>}
            price={price}
            currency={currency}
          />
        ) : null}
        {fees && currency && fees > 0 ? (
          <PriceItem
            label={t({ id: "component.ShoppingCart.feesLabel", message: "Frais de transaction" })}
            price={fees}
            currency={currency}
          />
        ) : null}
      </div>
      {!isEmpty(offerAvailabilityMessages) && (
        <div className="flex flex-row flex-wrap gap-2">
          {offerAvailabilityMessages.map((content, index) => (
            <Chip color="secondary" size="small" key={index}>
              {content}
            </Chip>
          ))}
        </div>
      )}

      {(options || []).map((option, i) => (
        <PriceItem key={i} label={option.title} price={option.price} currency={option.currency} />
      ))}
    </>
  );
};

const SummaryItem = ({
  icon: Icon,
  children,
  className,
  title,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: FC<any>;
  children: ReactNode;
  className?: string;
  title?: string;
}) => (
  <div
    className={clsx("font-semibold flex flex-row items-center leading-6 mr-3 last:mr-0", className)}
    title={title ?? undefined}
  >
    <Icon className="mr-1 text-sm" />
    <div>{children}</div>
  </div>
);

type RaceSummaryProps = {
  raceEdition: {
    date: Maybe<string>;
    time?: Maybe<string>;
    endTime?: Maybe<string>;
    endDate?: Maybe<string>;
    elevationGain?: Maybe<number>;
    elevationLoss?: Maybe<number>;
    distance: number;
    distanceUnit: DistanceUnitEnum;
    activities: Array<Activity>;
    status?: EventStatusEnum;
    race: {
      formattedTitle: string;
      isDateHidden: boolean;
      discipline: GenericDiscipline;
    };
  };
};

const RaceSummary = ({ raceEdition }: RaceSummaryProps) => {
  const { i18n } = useLingui();

  const noExtract = i18n._.bind(i18n);

  const race = raceEdition.race;
  const isMultiSports = getIsMultiSports(race.discipline);

  const { elevationGain, elevationLoss, distance, distanceUnit, activities, status } = raceEdition;

  const elevationText = elevationGain
    ? [
        elevationGain && `${formatNumber(elevationGain)}mD+`,
        elevationLoss && `${formatNumber(elevationLoss)}mD-`,
      ]
        .filter(Boolean)
        .join(" / ")
    : null;

  const startDate = raceEdition.date ? `${raceEdition.date}T${raceEdition.time || "00:00"}` : null;
  const endDate = raceEdition.endDate
    ? `${raceEdition.endDate}T${raceEdition.endTime || "00:00"}`
    : startDate;

  return (
    <>
      <div className="text-xs text-storm-grey flex flex-row items-center flex-wrap">
        {startDate && !race.isDateHidden ? (
          <SummaryItem icon={CalendarIcon} className="capitalize">
            {status === EventStatusEnum.ToBeAnnonced
              ? formatDate(i18n.locale, startDate, {
                  year: "numeric",
                  month: "short",
                })
              : formatDateRange(i18n.locale, startDate, endDate, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  // weekday: "short",
                  short: true,
                  startTime: !!raceEdition.time,
                  endTime: !!raceEdition.endTime,
                })}
          </SummaryItem>
        ) : null}
        {isMultiSports ? (
          <>
            {activities.map((a) => (
              <SummaryItem
                key={a.activity}
                className="gap-1"
                title={noExtract(getDisciplineTranslationId(a.activity))}
                icon={() => <ActivityIcon key={a.activity} type={a.activity} className="text-lg" />}
              >
                {formatDistance(i18n.locale as Locale, a.distance, a.distanceUnit)}
              </SummaryItem>
            ))}
          </>
        ) : race.discipline !== ActivityEnum.Other ? (
          <SummaryItem icon={RunningBasicIcon}>
            {formatDistance(i18n.locale as Locale, distance, distanceUnit)}
          </SummaryItem>
        ) : null}
        {elevationText && <SummaryItem icon={MountainPicto}>{elevationText}</SummaryItem>}
      </div>
      {/* {title && (
        <div className="leading-tight text-sm flex flex-row items-center mt-1 font-semibold">
          {title}
        </div>
      )}
      {description && (
        <div className="leading-tight text-sm flex flex-row items-center mt-1 ">{description}</div>
      )} */}
    </>
  );
};

type ShoppingCartProps = {
  isPaid?: boolean;
  cart?: { items: ShoppingCartItemProps["item"][] };
  total?: number;
};

const ShoppingCart = ({ cart, isPaid, total }: ShoppingCartProps) => {
  const items = cart?.items || [];

  // Pas ouf, on amériorera ça quand on gérera plusieurs dossards par commande
  const currency = map(items, "offer.currency").filter(Boolean).at(0);
  const event = map(items, "offer.raceEdition.race.event").filter(Boolean).at(0);
  const showOfferWarning = items.some((r) => r.offer && offerIsLimited(r.offer));

  return (
    <ShoppingCartContainer event={event} showOfferWarning={showOfferWarning}>
      <div className="mb-6 mt-4 flex flex-col gap-1">
        {items.map((item, i) => (
          <ShoppingCartItem key={i} item={item} />
        ))}
      </div>

      {items.length > 0 && typeof total === "number" && (
        <>
          <div className="leading-none texl-lg md:text-xl text-electric-blue font-bold flex flex-row justify-between">
            {isPaid ? (
              <Trans id="component.ShoppingCart.totalPaid">Montant payé</Trans>
            ) : (
              <Trans id="component.ShoppingCart.total">Total</Trans>
            )}
            <div className="">
              {total === 0 ? (
                <Trans id="generic.free">Gratuit</Trans>
              ) : (
                formatPrice(total, currency)
              )}
            </div>
          </div>
          {!isPaid &&
          total > 0 &&
          event.id === "231b382a-1f06-47c1-a50a-bf2a13b12c5d" /* Marathon de la loire */ ? (
            // eslint-disable-next-line lingui/no-unlocalized-strings
            <div className="text-xs text-storm-grey">
              Prix TTC, taux de TVA détaillés dans l’article 5 du règlement de la course
            </div>
          ) : null}
        </>
      )}
    </ShoppingCartContainer>
  );
};

export default ShoppingCart;
