import DirectionsBike from "@material-symbols/svg-400/outlined/directions_bike.svg";
import DirectionsRun from "@material-symbols/svg-400/outlined/directions_run.svg";
import DirectionsWalk from "@material-symbols/svg-400/outlined/directions_walk.svg";
import Kayaking from "@material-symbols/svg-400/outlined/kayaking.svg";
import NordicWalking from "@material-symbols/svg-400/outlined/nordic_walking.svg";
import Pool from "@material-symbols/svg-400/outlined/pool.svg";
import clsx from "clsx";

import { ActivityEnum } from "@kavval/constants";

// @ts-ignore
import styles from "./style.module.css";

const table: Record<ActivityEnum, typeof DirectionsRun> = {
  [ActivityEnum.Trail]: DirectionsRun,
  [ActivityEnum.Road]: DirectionsRun,
  [ActivityEnum.ObstacleRace]: DirectionsRun,
  [ActivityEnum.Walking]: DirectionsWalk,
  [ActivityEnum.Nordic]: NordicWalking,
  [ActivityEnum.Cycling]: DirectionsBike,
  [ActivityEnum.MountainBiking]: DirectionsBike,
  [ActivityEnum.CycloCross]: DirectionsBike,
  [ActivityEnum.GravelBiking]: DirectionsBike,
  [ActivityEnum.Swimming]: Pool,
  [ActivityEnum.BikeAndRun]: DirectionsBike,
  [ActivityEnum.Cross]: DirectionsRun,
  [ActivityEnum.Canoe]: Kayaking,
  [ActivityEnum.Other]: DirectionsRun,
};

interface Props {
  type: ActivityEnum;
  className?: string;
}

const ActivityIcon = ({ type, className }: Props) => {
  const Component = table[type];

  // @ts-ignore
  return Component ? <Component className={clsx(styles.Icon, className)} /> : null;
};

export default ActivityIcon;
