import { Cutoff } from "./Cutoff";
import { DistanceUnitEnum } from "./DistanceUnit";

export enum ActivityEnum {
  Trail = "trail",
  Road = "road",
  ObstacleRace = "obstacle_race",
  Walking = "walking",
  Nordic = "nordic",
  Cycling = "cycling",
  MountainBiking = "mountain_biking",
  CycloCross = "cyclo_cross",
  GravelBiking = "gravel_biking",
  Swimming = "swimming",
  BikeAndRun = "bike_and_run",
  Cross = "cross",
  Canoe = "canoe",
  Other = "other",
}

export type ElevationProperties = {
  elevationGain?: number | null;
  elevationLoss?: number | null;
};

export type Activity = ElevationProperties & {
  activity: ActivityEnum;
  distance: number;
  distanceUnit: DistanceUnitEnum;
  characteristics?: string[] | null;
  loops?: number | null;
  description?: string | null;
  traceId?: string | null;
  cutoffs?: Array<Cutoff> | null;
};

const activities = Object.values(ActivityEnum);

export default activities;
