import type { Njuko2PriceSchema } from "@api/ticketsProviders/njuko2/schema";

export type Njuko1OfferExtraData = {
  priceId: number;
  competitionId: number;
  registrationUrl?: string;
};

type Njuko2CommonFields = {
  /**
   * @description UUID de la compétition njuko2 chez nous (voir buildOffer dans Njuko2Competition)
   */
  njuko2CompetitionId: string;
  competitionStockOnNjuko?: number;
  overrideTitle?: boolean;
  njuko2Price: Njuko2PriceSchema;
  isFastBookingSupported: boolean;
};

export type Njuko2AffiliationOfferExtraData = Njuko2CommonFields;

export type Njuko2FastBookingOfferExtraData = Njuko2CommonFields & {
  originalStatus: OfferStatusEnum | null;
  originalVisibility?: boolean;
  visibilityOverride?: boolean | null; // null|undefined = pas d'override, true=visible, false=invisible
  availabilityOverride?: boolean | null; // null|undefined = pas d'override, true=disponible (si possible), false=indisponible
};

export type NjukoOriginOfferExtraData = {
  priceId?: number;
  competitionId?: number;
};

export type ExternalOfferExtraData = {
  registrationUrl?: string;
};

export type KmsOfferExtraData = {
  Prf_Id?: number;
  Eprv_Id?: number;
  Parc_Id?: number;
};

export enum OfferSeller {
  Kms = "kms",
  Njuko1 = "njuko_1",
  Njuko2 = "njuko_2",
  Njuko2Affiliation = "njuko_2_affiliation",
  Kavval = "kavval",
}

export enum OfferStatusEnum {
  // IMPORTANT !
  // Mettre à jour la méthode availabilitySqlCondition (models/Offer/index) si on ajoute des statuts ici
  RegistrationNotOpened = "registration_not_opened",
  Available = "available",
  SoldOut = "sold_out",
  Expired = "expired",
}

export enum OfferType {
  EventOption = "eventOption",
  Ticket = "ticket",
  Insurance = "insurance",
}

export enum TransferDestination {
  Organizer = "organizer",
  Njuko = "njuko",
  AssurConnect = "assur_connect",
}

export interface Transfer {
  destination: TransferDestination;
  amount: number;
}
