/* eslint-disable lingui/no-unlocalized-strings */
import clsx from "clsx";
import { isEmpty } from "lodash";

import RawHtml from "@kavval/ui/RawHtml";

import { BookingMessageType } from "@api/db/models/Edition/types";

import styles from "./styles.module.css";

const classNames: Partial<Record<BookingMessageType, string>> = {
  [BookingMessageType.Info]: "bg-blue-50 text-blue-800",
  [BookingMessageType.Warning]: "bg-orange text-dark-blue",
  [BookingMessageType.Citation]: "bg-blue-50 text-blue-800",
};

const BookingMessage = ({
  type,
  content,
}: {
  type: BookingMessageType;
  content: string | null;
}) => {
  if (isEmpty(content)) return null;

  return (
    <div
      className={clsx(
        classNames[type],
        styles.BookingMessage,
        "p-4 md:p-6  rounded-sm relative overflow-hidden"
      )}
    >
      {type === BookingMessageType.Citation && (
        <span className="z-1 absolute text-orange -top-20 opacity-20 -left-4 text-[400px] h-[40px] leading-none">
          “
        </span>
      )}
      <RawHtml innerHtml={content!} className="z-2 relative" />
    </div>
  );
};

export default BookingMessage;
