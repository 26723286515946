import type { ComponentPropsWithoutRef } from "react";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

import { Input } from "@kavval/ui";

import type { ExtraFieldProps } from "../../types";
import { formatError } from "../../utils";

type Props = Omit<ComponentPropsWithoutRef<typeof Input>, "name"> &
  ExtraFieldProps & { name: string };

const InputField = ({ registerOptions, error, ...props }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { name } = props;
  return (
    <Input
      {...props}
      required={!!registerOptions.required}
      {...register(name, registerOptions)}
      error={error || formatError(get(errors, name))}
    />
  );
};

export default InputField;
