import { t } from "@lingui/macro";
import type { GlobalError } from "react-hook-form";

import type { FieldsProps } from "./Field";

export const getKey = (field: FieldsProps): string => {
  if (field.type === "grid") {
    return `grid-${field.fields.map(getKey).join("-")}`;
  }

  // @ts-ignore
  return `${field.type}-${field.name}-${field.id}`;
};

export const formatError = (error?: GlobalError) => {
  if (!error) {
    return;
  }

  const { message, type } = error;

  if (message) {
    return message;
  }

  if (type === "required") {
    return t({
      id: "component.Form.fieldRequired",
      message: "Ce champ est requis",
    });
  }

  return t({
    id: "component.Form.fieldInvalid",
    message: "Ce champ est invalide",
  });
};
