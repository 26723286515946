// import styles from "./styles.module.css";

import type { ReactNode } from "react";
import { Trans } from "@lingui/macro";

import CheckIcon from "@kavval/ui/Icons/CheckIcon";

import { InfoBlock } from "@booking/components";

const SellingPoints = () => (
  <InfoBlock title={<Trans id="generic.kavvalSellingPoints.title">Les avantages Finishers</Trans>}>
    <div className="my-2 flex flex-row items-center">
      <CheckIcon className="mr-2 shrink-0" />{" "}
      <Trans id="generic.kavvalSellingPoints.certified">
        Distributeur certifié par l’organisateur
      </Trans>
    </div>
    <div className="my-2 flex flex-row items-center">
      <CheckIcon className="mr-2 shrink-0" />{" "}
      <Trans id="generic.kavvalSellingPoints.secured">Paiement 100% sécurisé</Trans>
    </div>
    <div className="my-2 flex flex-row items-center">
      <CheckIcon className="mr-2 shrink-0" />{" "}
      <Trans id="generic.kavvalSellingPoints.hotline">Service client dédié</Trans>
    </div>
    <div className="my-2 flex flex-row items-center">
      <CheckIcon className="mr-2 shrink-0" />{" "}
      <Trans id="generic.kavvalSellingPoints.fast">Achat rapide en quelques clics</Trans>
    </div>
  </InfoBlock>
);

type ShoppingCartLayoutProps = {
  rightContent?: ReactNode;
  withSellingPoints?: boolean;
  children: ReactNode;
};

const ShoppingCartLayout = ({
  rightContent,
  withSellingPoints,
  children,
}: ShoppingCartLayoutProps) => {
  return (
    <div className="md:flex md:flex-row-reverse">
      <div className="md:w-4/12 md:max-w-[400px] shrink-0 -mx-4 sm:-mx-8 md:mr-0 md:ml-10">
        <div className="md:sticky top-10">
          {rightContent}
          {withSellingPoints && (
            <div className="hidden md:block mt-10">
              <SellingPoints />
            </div>
          )}
        </div>
      </div>

      <div className="grow pb-0 md:pb-10">
        {children}
        {withSellingPoints && (
          <div className="md:hidden pt-10">
            <SellingPoints />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartLayout;
