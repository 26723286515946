import {
  isSameDay,
  isSameMonth,
  isSameSecond,
  isSameYear,
  parseISO,
  startOfMinute,
} from "date-fns";

import type { Locale } from "@kavval/lingui-config/locales";

type DateTimeFormatOptions = Intl.DateTimeFormatOptions;
type GenericDate = Date | string | number | null | undefined;
type Nullish = "" | null | undefined;
type NonNullGenericDate = Exclude<GenericDate, Nullish>;

const DEFAULT_FORMAT: DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "short",
};

export const getDate = (date: NonNullGenericDate) => {
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === "string") {
    return parseISO(date);
  }
  if (typeof date === "number") {
    return new Date(date);
  }

  return undefined as never;
};

export function formatDate(
  locale: Locale,
  date: NonNullGenericDate,
  format: DateTimeFormatOptions = DEFAULT_FORMAT
) {
  // should not happen
  if (!date) return "";

  if (/^fr/i.test(locale)) {
    // Fix output i2f format is xxxx, 10:00 => xxxx à 10h00
    const parts = new Intl.DateTimeFormat(locale, format).formatToParts(getDate(date));
    const hourIndex = parts.findIndex((p) => p.type === "hour");
    const minuteIndex = parts.findIndex((p) => p.type === "minute");

    if (hourIndex > 0) {
      const previousPart = parts[hourIndex - 1];
      if (
        previousPart.type === "literal" &&
        (previousPart.value === " " || previousPart.value === ", ")
      ) {
        previousPart.value = " à ";
      }
    }

    if (minuteIndex > 0) {
      const previousPart = parts[minuteIndex - 1];
      if (previousPart.type === "literal" && previousPart.value === ":") {
        previousPart.value = "h";
      }
    }

    return parts.map((p) => p.value).join("");
  }

  return new Intl.DateTimeFormat(locale, format).format(getDate(date));
}

export const formatDateTime = (
  locale: Locale,
  date: NonNullGenericDate,
  time?: string | null,
  format: DateTimeFormatOptions = DEFAULT_FORMAT
) => {
  // Should not happen
  if (!date) return "";

  let newDate = getDate(date);

  const f = { ...format };

  if (time) {
    const [hour, minute] = time.split(":");

    if (newDate) {
      newDate.setHours(parseInt(hour, 10));
      newDate.setMinutes(parseInt(minute, 10));
      newDate = startOfMinute(newDate);
    }
  } else {
    delete f.hour;
    delete f.minute;
  }

  return formatDate(locale, newDate!, f);
};

type LocalizeRange = (locale: Locale, from: string, to: string) => string;
export const localizeRange: LocalizeRange = (locale, from, to) => {
  const onlyHours = to.length <= 5;

  if (locale === "fr") {
    return onlyHours ? `du ${from.replace("à", "de")} à ${to}` : `du ${from} au ${to}`;
  }

  return `${from} ➜ ${to}`;
};

type FormatDateRangeOptions = {
  short?: boolean;
  monthOnly?: boolean;
  startTime?: boolean;
  endTime?: boolean;
};

export function formatDateRange(
  locale: Locale,
  start: NonNullGenericDate,
  end: NonNullGenericDate,
  options?: FormatDateRangeOptions
): string {
  const short = options?.short;
  const monthOnly = options?.monthOnly;
  const startTime = options?.startTime;
  const endTime = options?.endTime;

  // Should not happen
  if (!start || !end) return "";

  const startDate = getDate(start);
  const endDate = getDate(end);

  const startTimeFormat = startTime ? "numeric" : undefined;
  const endTimeFormat = endTime ? "numeric" : undefined;
  const year = short ? undefined : "numeric";
  const month = short ? "short" : "long";
  const weekday = monthOnly ? undefined : short ? undefined : "short";

  if (monthOnly) {
    return formatDate(locale, startDate, {
      year,
      month: "long",
    });
  }

  if (isSameDay(startDate, endDate)) {
    if (isSameSecond(startDate, endDate)) {
      return formatDate(locale, startDate, {
        year,
        month,
        day: "numeric",
        weekday,
        hour: startTimeFormat,
        minute: startTimeFormat,
      });
    } else {
      return localizeRange(
        locale,
        formatDate(locale, startDate, {
          year,
          month,
          day: "numeric",
          weekday,
          hour: startTimeFormat,
          minute: startTimeFormat,
        }),
        formatDate(locale, endDate, {
          hour: endTimeFormat,
          minute: endTimeFormat,
        })
      );
    }
  }

  if (isSameMonth(startDate, endDate)) {
    return localizeRange(
      locale,
      formatDate(locale, startDate, {
        day: "numeric",
        weekday,
        hour: startTimeFormat,
        minute: startTimeFormat,
      }),
      formatDate(locale, endDate, {
        year,
        month,
        day: "numeric",
        hour: endTimeFormat,
        minute: endTimeFormat,
      })
    );
  }

  if (isSameYear(startDate, endDate)) {
    return localizeRange(
      locale,
      formatDate(locale, startDate, {
        month: "short",
        day: "numeric",
        weekday,
        hour: startTimeFormat,
        minute: startTimeFormat,
      }),
      formatDate(locale, endDate, {
        year,
        month: "short",
        day: "numeric",
        weekday,
        hour: endTimeFormat,
        minute: endTimeFormat,
      })
    );
  }

  return localizeRange(
    locale,
    formatDate(locale, startDate, {
      year,
      month: "short",
      day: "numeric",
      weekday,
      hour: startTimeFormat,
      minute: startTimeFormat,
    }),
    formatDate(locale, endDate, {
      year,
      month: "short",
      day: "numeric",
      weekday,
      hour: endTimeFormat,
      minute: endTimeFormat,
    })
  );
}
