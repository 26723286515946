import type { ComponentPropsWithoutRef, FunctionComponent } from "react";
import { createElement, forwardRef, memo } from "react";
import clsx from "clsx";

import styles from "./style.module.css";

interface RawHtmlProps extends ComponentPropsWithoutRef<"div"> {
  innerHtml: string;
  // eslint-disable-next-line
  node?: FunctionComponent<any> | string;
  className?: string;
}

const RawHtml = forwardRef<HTMLDivElement, RawHtmlProps>(
  ({ innerHtml, node = "span", className, ...props }, ref) =>
    innerHtml
      ? createElement(node, {
          ...props,
          ref,
          className: clsx(className, styles.RawHtml),
          dangerouslySetInnerHTML: {
            __html: innerHtml,
          },
        })
      : null
);

RawHtml.displayName = "RawHtml";

export default memo(RawHtml);
