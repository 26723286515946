import type { Activity, GenericDiscipline } from "@kavval/constants";
import { ActivityEnum, DistanceUnitEnum, MultiSportsDisciplineEnum } from "@kavval/constants";

export const getDisciplineDetails = (discipline: GenericDiscipline) => {
  return disciplineDetails[discipline];
};

const defaultDistanceUnit = DistanceUnitEnum.Meters;

const disciplineDetails: Record<
  GenericDiscipline,
  {
    activities: Array<Omit<Activity, "distance">>;
    presets?: Record<string, Array<number>>;
  }
> = {
  [ActivityEnum.Road]: {
    activities: [
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Trail]: {
    activities: [
      {
        activity: ActivityEnum.Trail,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Cross]: {
    activities: [
      {
        activity: ActivityEnum.Cross,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.ObstacleRace]: {
    activities: [
      {
        activity: ActivityEnum.ObstacleRace,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Walking]: {
    activities: [
      {
        activity: ActivityEnum.Walking,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Nordic]: {
    activities: [
      {
        activity: ActivityEnum.Nordic,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Cycling]: {
    activities: [
      {
        activity: ActivityEnum.Cycling,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.MountainBiking]: {
    activities: [
      {
        activity: ActivityEnum.MountainBiking,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.GravelBiking]: {
    activities: [
      {
        activity: ActivityEnum.GravelBiking,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Swimming]: {
    activities: [
      {
        activity: ActivityEnum.Swimming,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.SwimRun]: {
    activities: [
      {
        activity: ActivityEnum.Swimming,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.SwimBike]: {
    activities: [
      {
        activity: ActivityEnum.Swimming,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Cycling,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.Duathlon]: {
    activities: [
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Cycling,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.Aquathlon]: {
    activities: [
      {
        activity: ActivityEnum.Swimming,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.Triathlon]: {
    activities: [
      {
        activity: ActivityEnum.Swimming,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Cycling,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.CrossTriathlon]: {
    activities: [
      {
        activity: ActivityEnum.Swimming,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.MountainBiking,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Trail,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.Cyclathlon]: {
    activities: [
      {
        activity: ActivityEnum.Cycling,
        distanceUnit: defaultDistanceUnit,
      },
      {
        activity: ActivityEnum.Road,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.BikeAndRun]: {
    activities: [
      {
        activity: ActivityEnum.BikeAndRun,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.CycloCross]: {
    activities: [
      {
        activity: ActivityEnum.CycloCross,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [ActivityEnum.Canoe]: {
    activities: [
      {
        activity: ActivityEnum.Canoe,
        distanceUnit: defaultDistanceUnit,
      },
    ],
  },
  [MultiSportsDisciplineEnum.Multisports]: {
    // This is a generic multipsport
    activities: [],
  },
  [ActivityEnum.Other]: {
    // This is a generic multipsport
    activities: [],
  },
};
